<template>
  <div>
    <span v-for="(item, i) in requirements" :key="i" class="requirements">
      <v-icon v-if="item.value == true" color="primary">mdi-check</v-icon>
      <v-icon v-if="item.value == false && password == ''" color="black"
        >mdi-circle-small</v-icon
      >
      <v-icon v-if="item.value == false && password != ''" color="red"
        >mdi-close</v-icon
      >{{ $t(item.text) }} <br
    /></span>
  </div>
</template>

<script>
import { specialRequired } from "@/shared/helpers/fieldRequirements.js";
import { checkPasswordMinCharacter } from "@/shared/helpers/validatePassword.js";

export default {
  name: "PasswordChecklist",
  props: {
    password: String,
  },
  data() {
    return {
      checkPasswordMinCharacter,
      requirements: [
        { text: "at_least_eight_characters", value: false },
        { text: "at_least_a_number", value: false },
        { text: "at_least_lower", value: false },
        { text: "at-least_upper", value: false },
        { text: "password_rule_4", value: false },
        { text: "password_min_character", value: false },
      ],
    };
  },
  mounted() {
    this.bus.$on;
  },
  methods: {
    check_password_number(value) {
      return /[0-9]/.test(value);
    },
    check_password_lower(value) {
      return /[a-z]/.test(value);
    },
    check_password_upper(value) {
      return /[A-Z]/.test(value);
    },
    verifyRequirements() {
      if (this.password.length >= 8) {
        this.requirements[0].value = true;
      } else {
        this.requirements[0].value = false;
      }
      if (this.check_password_number(this.password)) {
        this.requirements[1].value = true;
      } else {
        this.requirements[1].value = false;
      }
      if (this.check_password_lower(this.password)) {
        this.requirements[2].value = true;
      } else {
        this.requirements[2].value = false;
      }
      if (this.check_password_upper(this.password)) {
        this.requirements[3].value = true;
      } else {
        this.requirements[3].value = false;
      }
      if (specialRequired(this.password)) {
        this.requirements[4].value = true;
      } else {
        this.requirements[4].value = false;
      }
      if (this.checkPasswordMinCharacter(this.password)) {
        this.requirements[5].value = true;
      } else {
        this.requirements[5].value = false;
      }
    },
    checkAllRequirements() {
      return this.requirements.some((req) => {
        return req.value;
      });
    },
    checkFullPasswordIsValid() {
      // pegando uma regra que ainda esteja false (ou seja, não foi preenchida)
      const findRequiredRule = this.requirements.find(
        (req) => req.value === false
      );

      // caso exista alguma regra para ser preenchida, retorna false.
      if (findRequiredRule) return findRequiredRule.value;

      // se retornar true, a senha é válida.
      return true;
    },
  },
};
</script>

<style scoped>
.requirements {
  font-size: 14px;
  font-weight: 300;
  color: var(--dark);
}
</style>
