<template>
  <v-card class="dxa_modal">
    <v-card-title>
      <h4 v-if="CustomMessage" class="dxa_modal_title h4">
        {{ CustomMessage }} {{ name != null ? name.concat("?") : "?" }}
      </h4>
      <h4 v-else class="dxa_modal_title h4">
        {{ $t("delete_confirmation")
        }}{{ name != null ? name.concat("?") : "?" }}
      </h4>
    </v-card-title>

    <div
      v-if="alert && alert.show"
      class="mt-5"
      style="padding-left: 15px; padding-right: 15px"
    >
      <v-alert dense style="padding: 12px" outlined :type="alert.type">
        {{ $t(alert.message) }}
      </v-alert>
    </div>

    <v-card-text>
      <v-card-actions>
        <v-btn
          data-test="DeleteConfirmationModal:BtnConfirm"
          class="dxa_modal_btnSuccess"
          color="primary"
          min-width="100"
          :loading="loading"
          @click="delete_click"
          >{{ $t("ok") }}</v-btn
        >
        <v-btn
          class="dxa_modal_btnError"
          color="red"
          min-width="100"
          @click="$emit('close')"
          >{{ $t("cancel") }}</v-btn
        >
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "DeleteConfirmationModal",
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    confirmed: false,
  }),

  async created() {
    this.loading = false;
  },
  computed: {},
  methods: {
    delete_click: function () {
      try {
        this.loading = true;
        this.$emit("delete", this.obj);
        this.loading = false;

        if (this.useLocalLoading) {
          this.loading = this.localLoading;
        }
      } catch (err) {}
    },
  },
  props: {
    obj: Object,
    name: String,
    CustomMessage: String,
    localLoading: Boolean,
    useLocalLoading: Boolean,
    alert: Object,
  },
};
</script>
