<template>
  <v-container v-if="!loading" id="add-company" fluid tag="section">
    <base-material-card
      color="secondary"
      icon="mdi-cog"
      :title="$t('settings')"
      class="px-5 py-3"
    >
      <v-tabs v-model="tabs" @change="reset_alert">
        <v-tab>{{ $t("change_password") }}</v-tab>
        <v-tab>{{ $t("delete_account") }}</v-tab>
      </v-tabs>

      <ChangePassword v-if="tabs == 0" @alert="show_alert"></ChangePassword>
      <DeleteAccount v-if="tabs == 1" @alert="show_alert"></DeleteAccount>
      <v-alert
        class="ma-2"
        v-if="alert_msg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alert_msg) }}
      </v-alert>
    </base-material-card>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import ChangePassword from "./tabs/ChangePassword";
import DeleteAccount from "./tabs/DeleteAccount";

export default {
  name: "Settings",

  components: {
    ChangePassword,
    DeleteAccount,
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    company_id: null,
    success: null,
    alert_msg: null,
    profile: null,
    tabs: 0,
  }),

  computed: {},

  async created() {
    this.loading = true;
    this.apiService
      .getRequest("user/profile")
      .then((resp) => {
        this.profile = JSON.parse(resp);
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
  },

  methods: {
    reset_alert: function () {
      this.success = null;
      this.alert_msg = null;
    },
    show_alert: function (success, msg) {
      this.success = success;
      this.alert_msg = msg;
    },
  },
};
</script>
