import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"dxa_modal"},[_c(VCardTitle,[(_vm.CustomMessage)?_c('h4',{staticClass:"dxa_modal_title h4"},[_vm._v(" "+_vm._s(_vm.CustomMessage)+" "+_vm._s(_vm.name != null ? _vm.name.concat("?") : "?")+" ")]):_c('h4',{staticClass:"dxa_modal_title h4"},[_vm._v(" "+_vm._s(_vm.$t("delete_confirmation"))+_vm._s(_vm.name != null ? _vm.name.concat("?") : "?")+" ")])]),(_vm.alert && _vm.alert.show)?_c('div',{staticClass:"mt-5",staticStyle:{"padding-left":"15px","padding-right":"15px"}},[_c(VAlert,{staticStyle:{"padding":"12px"},attrs:{"dense":"","outlined":"","type":_vm.alert.type}},[_vm._v(" "+_vm._s(_vm.$t(_vm.alert.message))+" ")])],1):_vm._e(),_c(VCardText,[_c(VCardActions,[_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"data-test":"DeleteConfirmationModal:BtnConfirm","color":"primary","min-width":"100","loading":_vm.loading},on:{"click":_vm.delete_click}},[_vm._v(_vm._s(_vm.$t("ok")))]),_c(VBtn,{staticClass:"dxa_modal_btnError",attrs:{"color":"red","min-width":"100"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }