<template>
  <v-form
    @submit.stop.prevent="checkPasswordForm()"
    ref="passwordForm"
    v-model="valid"
    :lazy-validation="lazy"
  >
    <v-card-text>
      <v-alert v-if="$route.query.firstLogin" dense color="primary" type="info">
        {{ $t("first_login_password_redefinition") }}
      </v-alert>
      <v-text-field
        class="my-input-style"
        @keydown.space.prevent
        :label="$t('current_password')"
        v-model="currentPassword"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[required]"
        :type="show1 ? 'text' : 'password'"
        @click:append="show1 = !show1"
        data-test="Settings:ChangePassword:CurrentPassword"
      />
      <v-text-field
        class="my-input-style"
        @keyup="verifyRequirements"
        @keydown.space.prevent
        ref="password"
        :label="$t('new_password')"
        v-model="password"
        :rules="[(v) => (!!v && verifyAllPasswordRequirements()) || required()]"
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show2 ? 'text' : 'password'"
        @click:append="show2 = !show2"
        data-test="Settings:ChangePassword:NewPassword1"
      />

      <v-text-field
        class="my-input-style"
        v-model="rePassword"
        :label="$t('re_password')"
        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[required, passwordsMatch]"
        :type="show3 ? 'text' : 'password'"
        @click:append="show3 = !show3"
        data-test="Settings:ChangePassword:NewPassword2"
      />

      <ValidateStrengthPassword :userPassword="userPassword" />
      <PasswordChecklist :password="password" ref="passwordChecklist" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          type="submit"
          min-width="100"
          :loading="loading"
          class="mr-0"
          data-test="Settings:ChangePassword:Save"
          >{{ $t("save") }}</v-btn
        >
      </v-card-actions>
    </v-card-text>
  </v-form>
</template>

<script>
import ApiService from "@/services/ApiService";
import PasswordChecklist from "@/components/dxa/PasswordChecklist/PasswordChecklist.vue";
import ValidateStrengthPassword from "@/components/dxa/Password/ValidateStrengthPassword";

export default {
  name: "ChangePassword",
  components: { PasswordChecklist, ValidateStrengthPassword },
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    company_id: null,
    edit: false,
    valid: true,
    lazy: false,
    show1: false,
    show2: false,
    show3: false,
    currentPassword: "",
    password: "",
    rePassword: "",
    userPassword: "",
  }),

  computed: {
    passwordsMatch() {
      return () =>
        this.password === this.rePassword || this.$t("pswds_dnt_match");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  props: {},

  methods: {
    checkPasswordForm() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      let result = this.$refs.passwordForm.validate();
      let allPasswordIsValid =
        this.$refs.passwordChecklist.checkFullPasswordIsValid();

      if (result && allPasswordIsValid) {
        this.update_password();
      } else {
        this.loading = false;
      }
    },
    verifyRequirements() {
      this.userPassword = this.password;
      this.$refs.passwordChecklist.verifyRequirements();
      this.$refs.password.validate();
    },
    verifyAllPasswordRequirements() {
      return this.$refs.passwordChecklist.checkAllRequirements();
    },
    update_password() {
      this.apiService
        .postRequest("user/password/update", {
          CurrentPassword: this.currentPassword,
          NewPassword: this.password,
        })
        .then((resp) => {
          this.$refs.passwordForm.reset();
          this.$emit("alert", true, this.$t("saved"));
          let user = JSON.parse(localStorage.getItem("user"));
          user.mustRenewPassword = false;
          this.userPassword = "";

          this.$store.commit("SET_LOGGED_USER", JSON.stringify(user));
          this.$router.replace({ query: undefined });
          this.loading = false;
        })
        .catch((error) => {
          this.$emit("alert", false, error.body.message);
          this.error = error.body.message;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.my-input-style >>> .v-select__slot input,
.my-input-style >>> .v-select__slot span,
.my-input-style >>> .v-text-field__slot input,
.my-input-style >>> div.v-select__slot div.v-select__selections div,
.my-input-style
  >>> div.v-select__slot
  div.v-input__append-inner
  div.v-input__icon
  i {
  color: var(--dark) !important;
}
</style>
