<template>
  <div class="text-center my-4">
    <v-btn color="red" @click="delete_dialog = true">
      {{ $t("delete_account") }}
    </v-btn>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="null"
        :name="$t('your_account')"
        @close="delete_dialog = false"
        @delete="delete_account"
      ></DeleteConfirmationModal>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
export default {
  name: "DeleteAccount",

  components: {
    DeleteConfirmationModal,
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    delete_dialog: false,
  }),

  computed: {},
  props: {},

  methods: {
    delete_account() {
      this.loading = true;
      this.apiService
        .deleteRequest("user/account/delete")
        .then((resp) => {
          this.$router.push("/auth");
          this.loading = false;
        })
        .catch((error) => {
          this.$emit("alert", false, error.body.message);
          this.error = error.body.message;
          this.loading = false;
        });
    },
  },
};
</script>
